import React from "react";
import styles from "./brands.module.scss";

import Santander from "../../../assets/svg/logoSantander.svg";
import Mastercard from "../../../assets/svg/logoMastercard.svg";
import Sura from "../../../assets/svg/logoSura.svg";
import Zurich from "../../../assets/svg/logoZurich.svg";
import Google from "../../../assets/svg/logoGoogle.svg";
import Microsoft from "../../../assets/svg/logoMicrosoft.svg";
import C6 from "../../../assets/image/C6.png";
import PagoNXT from "../../../assets/image/pago-nxt.png";
import Credicorp from "../../../assets/image/Credicorp.png";
import Bind from "../../../assets/image/Bind.png";
import Superdigital from "../../../assets/image/Superdigital.png";
import Coopeuch from "../../../assets/image/Coopeuch.png";
import FundacionDonde from "../../../assets/image/FundacionDonde.png";
import Binaria from "../../../assets/image/logobinariaa.png";
import Assegur from "../../../assets/image/ASSEGUR_LOGO.png";
import Sudameris from "../../../assets/image/sudameris_logo.png";
import Galicia from "../../../assets/image/galicia_logo.png";
import Atlas from "../../../assets/image/atlas_logo.png";
import BrbCard from "../../../assets/image/brbcard_logo.png";
import Itau from "../../../assets/image/ITAU.png";
import LeeBank from "../../../assets/image/leebank_logo.png";
import LetsBank from "../../../assets/image/logo_letsbank.png";
import N26 from "../../../assets/image/banco_N26.png";
import Latam from "../../../assets/image/latam_air.png";
import Arbi from "../../../assets/image/banco_arbi.png";
import Ame from "../../../assets/image/banco_ame.png";
import BdB from "../../../assets/image/banco_do_brasil.png";
import Credsystem from "../../../assets/image/Credsystem.png";
import Mais from "../../../assets/image/MAIS.png";
import DIVISERV from "../../../assets/image/DIVISERV.png";
import JazzTech from "../../../assets/image/jazz-tech.png";
import K8 from "../../../assets/image/K8.png";
import MICROTECH from "../../../assets/image/MICROTECH.png";
import PISMO from "../../../assets/image/PISMO.png";
import transbank from "../../../assets/image/transbank.png";
import BancoRegional from "../../../assets/image/Regional.png";
import Coelsa from "../../../assets/image/Coelsa.png";
import Azeta from "../../../assets/image/Azeta.png";
import BancoCorrientes from "../../../assets/image/Banco-corrientes.png";
import HospitalBritanico from "../../../assets/image/hospital_britanico.png";
import btf from "../../../assets/image/BTF.png";
import CondoCash from "../../../assets/image/condo_cash.png";
import Interbank from "../../../assets/image/interbank.png";
import Rede from "../../../assets/image/REDE.png";
import Basa from "../../../assets/image/Banco-basa.png";
import Bci from "../../../assets/image/BCI.png";
import Aws from "../../../assets/image/AWS.png";
import Sicredi from "../../../assets/image/Sicredi.png";

import DividerCenter from "@atoms/DividerCenter";
import { useOnScreen } from "../../../hooks/useOnScreen";
import { useTranslation } from "gatsby-plugin-react-i18next";

const brandsHome = [
  { icon: Credicorp, alt: "Logo Credicorp" },
  { icon: BdB, alt: "Logo Banco do Brasil" },
  { icon: Atlas, alt: "Logo Atlas" },
  { icon: Sudameris, alt: "Logo Sudameris" },
  { icon: PISMO, alt: "PISMO" },
  { icon: Santander, alt: "Logo Santander" },
  { icon: Assegur, alt: "Logo Assegur" },
  { icon: Itau, alt: "Logo Itau" },
  { icon: Interbank, alt: "Logo Interbank" },
  { icon: Rede, alt: "Logo REDE" },
  { icon: Basa, alt: "Logo BancoBasa" },
  { icon: Bci, alt: "Logo BCI" },
  { icon: Aws, alt: "Logo AWS" },
  { icon: BancoRegional, alt: "Logo Regional" },
  { icon: Coelsa, alt: "Logo Coelsa" },
  { icon: BancoCorrientes, alt: "Logo Banco Corrientes" },
  { icon: Azeta, alt: "Logo Azeta" },
  { icon: LetsBank, alt: "Logo Lets Bank" },
  { icon: N26, alt: "Logo SmartBank" },
  { icon: C6, alt: "Logo C6" },
  { icon: Google, alt: "Logo Google" },
  { icon: Latam, alt: "Logo Latam" },
  { icon: Microsoft, alt: "Logo Microsoft" },
  { icon: DIVISERV, alt: "DIVISERV" },
  { icon: MICROTECH, alt: "MICROTECH" },
  { icon: Zurich, alt: "Logo Zurich" },
  { icon: HospitalBritanico, alt: "Logo Hospital Britanico" },
  { icon: Mastercard, alt: "Logo Mastercard" },
  { icon: LeeBank, alt: "Logo Lee Bank" },
  { icon: Galicia, alt: "Logo Galicia" },
  { icon: transbank, alt: "transbank" },
  { icon: Sicredi, alt: "Logo Sicredi" },
  { icon: Superdigital, alt: "Logo Superdigital" },
  { icon: Coopeuch, alt: "Logo Coopeuch" },
  { icon: Binaria, alt: "Logo Binaria" },
  { icon: btf, alt: "Logo BTF" },
  { icon: Credsystem, alt: "Credsystem" },
  { icon: Sura, alt: "Logo Sura" },
  { icon: BrbCard, alt: "Logo BrbCard" },
  { icon: PagoNXT, alt: "Logo PagoNxt" },
  { icon: K8, alt: "K8" },
  { icon: Mais, alt: "Mais" },
  { icon: CondoCash, alt: "Logo CondoCash" },
  { icon: JazzTech, alt: "JazzTech" },
  { icon: Bind, alt: "Logo Bind" },
  { icon: Ame, alt: "Logo AME" },
  { icon: Arbi, alt: "Logo Arbi" },
  { icon: FundacionDonde, alt: "Logo FundacionDonde" },
];

const brandsServices = [
  { icon: Credicorp, alt: "Logo Credicorp" },
  { icon: BdB, alt: "Logo Banco do Brasil" },
  { icon: Atlas, alt: "Logo Atlas" },
  { icon: Sudameris, alt: "Logo Sudameris" },
  { icon: PISMO, alt: "PISMO" },
  { icon: Santander, alt: "Logo Santander" },
  { icon: Assegur, alt: "Logo Assegur" },
  { icon: Itau, alt: "Logo Itau" },
  { icon: Interbank, alt: "Logo Interbank" },
  { icon: Rede, alt: "Logo REDE" },
  { icon: Basa, alt: "Logo BancoBasa" },
  { icon: Bci, alt: "Logo BCI" },
  { icon: Aws, alt: "Logo AWS" },
  { icon: BancoRegional, alt: "Logo Regional" },
  { icon: Coelsa, alt: "Logo Coelsa" },
  { icon: BancoCorrientes, alt: "Logo Banco Corrientes" },
  { icon: Azeta, alt: "Logo Azeta" },
  { icon: LetsBank, alt: "Logo Lets Bank" },
  { icon: N26, alt: "Logo SmartBank" },
  { icon: C6, alt: "Logo C6" },
  { icon: Google, alt: "Logo Google" },
  { icon: Latam, alt: "Logo Latam" },
  { icon: Microsoft, alt: "Logo Microsoft" },
  { icon: DIVISERV, alt: "DIVISERV" },
  { icon: MICROTECH, alt: "MICROTECH" },
  { icon: Zurich, alt: "Logo Zurich" },
  { icon: HospitalBritanico, alt: "Logo Hospital Britanico" },
    { icon: Mastercard, alt: "Logo Mastercard" },
  { icon: LeeBank, alt: "Logo Lee Bank" },
  { icon: Galicia, alt: "Logo Galicia" },
  { icon: transbank, alt: "transbank" },
   { icon: Sicredi, alt: "Logo Sicredi" },
  { icon: Superdigital, alt: "Logo Superdigital" },
  { icon: Coopeuch, alt: "Logo Coopeuch" },
  { icon: Binaria, alt: "Logo Binaria" },
  { icon: btf, alt: "Logo BTF" },
  { icon: Credsystem, alt: "Credsystem" },
  { icon: Sura, alt: "Logo Sura" },
  { icon: BrbCard, alt: "Logo BrbCard" },
  { icon: PagoNXT, alt: "Logo PagoNxt" },
  { icon: K8, alt: "K8" },
  { icon: Mais, alt: "Mais" },
  { icon: CondoCash, alt: "Logo CondoCash" },
  { icon: JazzTech, alt: "JazzTech" },
  { icon: Bind, alt: "Logo Bind" },
  { icon: Ame, alt: "Logo AME" },
  { icon: Arbi, alt: "Logo Arbi" },
  { icon: FundacionDonde, alt: "Logo FundacionDonde" },
];

const slideServices = [...brandsServices];
const slides = [...brandsHome];

const Brands = ({ showTitle, opacity, mt, gradients, isHome, isServices }) => {
  const [brandsRef, isBrandsVisible] = useOnScreen({ threshold: 0.3 });
  const { t } = useTranslation();

  const renderIcons = ({ icon, alt }, i) => (
    <div className={styles.brandsDirectionItem} key={`${alt}_${i}`}>
      <img
        className={styles.brandsDirectionItemImg}
        src={icon}
        alt={alt}
        loading="lazy"
      />
    </div>
  );

  return (
    <section ref={brandsRef} className={styles.section}>
      <DividerCenter mt={mt} />
      {showTitle && (
        <div className="mb36">
          <p>{t("FOOTER.TRUST")}</p>
        </div>
      )}
      <div className={styles.brands}>
        {gradients && (
          <>
            <div className={styles.brandsLeftGradient} />
            <div className={styles.brandsRightGradient} />
          </>
        )}
        <div
          className={`${styles.brandsDirection} ${
            isBrandsVisible ? styles.active : ""
          }`}
          style={{ opacity }}
        >
          {isHome
            ? slides.map(renderIcons)
            : isServices && slideServices.map(renderIcons)}
        </div>
      </div>
    </section>
  );
};

export default Brands;
